$('.indexBannerSlider').slick({
    autoplay: true, // 自動でスクロール
    autoplaySpeed: 4000, // 自動再生のスライド切り替えまでの時間を設定
    speed: 1200, // スライドが流れる速度を設定
    arrows: false,
    dots: false,
    fade: true,
    pauseOnFocus: false,//フォーカスで一時停止
    pauseOnHover: false,//マウスホバーで一時停止
    pauseOnDotsHover: false,//ドットナビをマウスホバーで一時停止
    adaptiveHeight:true,//高さの自動調節
});